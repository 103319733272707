@import '~@angular/material/theming';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.app-root {
  &-auto-height {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
}

//https://github.com/creativetimofficial/ct-material-dashboard-pro-angular/issues/351
.cdk-global-overlay-wrapper,
.cdk-overlay-container,
.cdk-overlay-pane,
.cdk-overlay-connected-position-bounding-box {
  z-index: 10000 !important;
  }

  //override of the filter overlay to fix issue with drop downs showing under a modal dialog - jwg
.p-column-filter-overlay {
    background: #fff;
    color: rgba(0, 0, 0, 0.87);
    border: 0 none;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
    min-width: 12.5rem;
    z-index:99999 !important;
}
